.top-button[data-v-435c1e02] {
  position: absolute;
  right: 300px;
  width: 50%;
  height: 60px;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.top-button > div[data-v-435c1e02] {
    cursor: pointer;
    margin-left: 20px;
}
.title[data-v-435c1e02] {
  position: absolute;
  height: 60px;
  left: 80px;
  top: 0;
}
.title span[data-v-435c1e02] {
    font-size: var(--rootFontSize2);
    font-weight: 600;
    letter-spacing: 6px;
}
.en-title[data-v-435c1e02] {
  position: absolute;
  height: 60px;
  left: 80px;
  top: 0;
}
.en-title span[data-v-435c1e02] {
    font-size: var(--rootFontSize2);
    font-weight: 600;
}
