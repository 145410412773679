.tips[data-v-46793a8c] {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 900;
}
[data-v-46793a8c] .el-form-item__label {
  width: 200px !important;
}
[data-v-46793a8c] .el-form-item__content {
  margin-left: 200px !important;
}
