.header-wrap[data-v-2d288a90] {
  line-height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 5px;
  background-color: #0270c1;
  color: #ffffff;
}
.header-wrap .header-left[data-v-2d288a90] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 49%;
}
.header-wrap .header-right[data-v-2d288a90] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 49%;
}
.header-wrap .header-right div[data-v-2d288a90] {
      margin: 0 5px;
      font-size: 14px;
}
.header-wrap .header-right .logout-icon i[data-v-2d288a90] {
      font-size: 18px;
      cursor: pointer;
}
.header-wrap .header-right .header-info-box[data-v-2d288a90] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.header-wrap .header-right .header-info-box div[data-v-2d288a90] {
        height: 36px;
        line-height: 36px;
        border-radius: 5px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.header-wrap .header-right .header-info-box .switch-dropdown[data-v-2d288a90] {
        padding: 0;
        margin: 0;
        color: white;
}
